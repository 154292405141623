import { Entity, EntityBuilder, Type } from '@jobilla/entity';
import { AuthenticatedUserFeatures } from '@entities/authentication/authenticated-user-features.entity';
import { IncomeWithdrawalFeature } from '@entities/advanced-payment/income-withdrawal-feature.enum';
import { UserRole } from '@entities/users/user-role.enum';
import { NonEnumerable } from '@core/decorators/non-enumerable.decorator';
import { UserRoleUtils } from '@utils/user-role.util';
import { UserFeature } from '@entities/users/user-feature.enum';

export class AuthenticatedUser extends Entity {
  public id: number = null;
  public email: string = null;
  public roleId: UserRole = null;
  public firstName: string = null;
  public lastName: string = null;
  public companyId: number = null;
  public companyName: string = null;
  public clientId: number = null;
  public clientName: string = null;
  public activeIncomeWithdrawalFeature: IncomeWithdrawalFeature = null;
  public phoneNumber: string = null;
  public lastNotificationCheckedAt: string = null;
  public termsAgreed: string = null;
  public countryCode: string = null;
  @Type(AuthenticatedUserFeatures)
  public availableFeatures: AuthenticatedUserFeatures = new AuthenticatedUserFeatures();

  @NonEnumerable
  private _initials: string = null;

  public constructor(initData?: Partial<AuthenticatedUser>) {
    super();
    EntityBuilder.fill(this, initData as any);
  }
  public get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public get initials(): string {
    if (this._initials) {
      return this._initials;
    }
    this._initials = this.firstName?.charAt(0).toUpperCase() + this.lastName?.charAt(0).toUpperCase();
    return this._initials;
  }

  public hasFeature(feature: UserFeature): boolean {
    switch (feature) {
      case UserFeature.MultiFactorAuthentication:
        return this.availableFeatures.multiFactorAuthentication;
      case UserFeature.PaymentReport:
        return this.availableFeatures.advancedReports;
      case UserFeature.Benefits:
        return this.availableFeatures.benefits;
      case UserFeature.Expenses:
        return this.availableFeatures.expenses;
      case UserFeature.Paystubs:
        return this.availableFeatures.paystubs;
      case UserFeature.FlatFeeSubscription:
        return this.availableFeatures.isFlatFeeSubscriber;
      case UserFeature.DailyPay:
        return this.activeIncomeWithdrawalFeature === IncomeWithdrawalFeature.DailyPay;
      case UserFeature.WeeklyPay:
        return this.activeIncomeWithdrawalFeature === IncomeWithdrawalFeature.WeeklyPay;
      case UserFeature.PerDiem:
        return this.activeIncomeWithdrawalFeature === IncomeWithdrawalFeature.PerDiem;
      case UserFeature.TimesheetEdit:
        return this.availableFeatures.timesheetEdit;
      case UserFeature.InstantQueue:
        return this.availableFeatures.instantQueue;
      case UserFeature.InstantAutoPayment:
        return this.availableFeatures.instantAutoPayment;
      case UserFeature.RegularAutoPayment:
        return this.availableFeatures.regularAutoPayment;
      default:
        throw new Error('Feature does not exist');
    }
  }

  public get isSuperAdmin(): boolean {
    return UserRoleUtils.isSuperAdmin(this.roleId);
  }

  public get isAdminOrGreater(): boolean {
    return UserRoleUtils.isAdminOrGreater(this.roleId);
  }

  public get isManager(): boolean {
    return UserRoleUtils.isManager(this.roleId);
  }

  public get isApprover(): boolean {
    return UserRoleUtils.isApprover(this.roleId);
  }

  public get isWorker(): boolean {
    return UserRoleUtils.isWorker(this.roleId);
  }

  public get isManagerOrGreater(): boolean {
    return UserRoleUtils.isManagerOrGreater(this.roleId);
  }
}

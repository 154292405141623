import { LabelValue } from '@core/types/label-value.type';
import { UserRole } from '@entities/users/user-role.enum';

export class UserRoleUtils {
  // order of roles is crucial in `getScopedRoles` top is highest
  private static availableRoles = [
    {
      label: 'Super Admin',
      value: UserRole.SuperAdmin,
    },
    {
      label: 'Admin',
      value: UserRole.Admin,
    },
    {
      label: 'Manager',
      value: UserRole.Manager,
    },
    {
      label: 'Approver',
      value: UserRole.Approver,
    },
    {
      label: 'Worker',
      value: UserRole.Worker,
    },
  ];

  public static getScopedRoles(roleCeiling: UserRole, exclude: UserRole[] = []): LabelValue<UserRole>[] {
    const roles: LabelValue<UserRole>[] = [];
    let isSameRoleOrLower: boolean = false;
    const filteredRoles = this.availableRoles.filter((r) => !exclude.includes(r.value));
    for (const role of filteredRoles) {
      if (role.value === roleCeiling || isSameRoleOrLower) {
        isSameRoleOrLower = true;
        roles.push(role);
      }
    }
    return roles;
  }

  public static getRoleLabel(role: UserRole): string {
    return this.availableRoles.find((r) => r.value === role)?.label;
  }

  public static getRole(role: UserRole): LabelValue<UserRole> {
    return this.availableRoles.find((r) => r.value === role);
  }

  public static isSuperAdmin(roleId: UserRole): boolean {
    return roleId === UserRole.SuperAdmin;
  }

  public static isAdminOrGreater(roleId: UserRole): boolean {
    return roleId === UserRole.Admin || roleId === UserRole.SuperAdmin;
  }

  public static isManager(roleId: UserRole): boolean {
    return roleId === UserRole.Manager;
  }

  public static isApprover(roleId: UserRole): boolean {
    return roleId === UserRole.Approver;
  }

  public static isWorker(roleId: UserRole): boolean {
    return roleId === UserRole.Worker;
  }

  public static isManagerOrGreater(roleId: UserRole): boolean {
    return this.isManager(roleId) || this.isAdminOrGreater(roleId);
  }
}

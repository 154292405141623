import { DatePreset } from '@core/types/date-preset.enum';
import { IDateRange } from '@components/selects/date-range-picker/date-range-picker.component';
import { format } from 'date-fns';
import merge from 'lodash-es/merge';

export const enum QueryFilterOperator {
  Like = 'like',
  iLike = 'iLike',
  Equal = 'eq',
  GreaterThan = 'gt',
  GreaterThanEqual = 'gte',
  LessThan = 'lt',
  LessThanEqual = 'lte',
  Preset = 'preset',
  Range = 'range',
  In = 'in',
  NotIn = 'notIn',
}

type StringQueryFilterOperators =
  | QueryFilterOperator.Equal
  | QueryFilterOperator.Like
  | QueryFilterOperator.iLike
  | QueryFilterOperator.In
  | QueryFilterOperator.NotIn;
type NumberQueryFilterOperators =
  | QueryFilterOperator.Equal
  | QueryFilterOperator.GreaterThan
  | QueryFilterOperator.GreaterThanEqual
  | QueryFilterOperator.LessThan
  | QueryFilterOperator.LessThanEqual
  | QueryFilterOperator.In
  | QueryFilterOperator.NotIn;
type DateQueryFilterOperators =
  | QueryFilterOperator.Equal
  | QueryFilterOperator.GreaterThan
  | QueryFilterOperator.GreaterThanEqual
  | QueryFilterOperator.LessThan
  | QueryFilterOperator.LessThanEqual
  | QueryFilterOperator.Range
  | QueryFilterOperator.Preset;
type ParamsOrUrl = string | { [filterKey: string]: any };
export type QueryFilter = { value: string | number; operator: QueryFilterOperator; metadata?: any };
export type QueryFiltersObject = { [key: string]: QueryFilter };
export class QueryFilters {
  private static VALUE_TOKEN = '[value]';
  private static OPERATOR_TOKEN = '[operator]';
  private static filterKeyRegex = new RegExp(/^(filter\[([a-z0-9]+)]\[([a-z0-9]+)])$/i);
  private filters: QueryFiltersObject = {};

  public addStringFilter(key: string, value: string, operator: StringQueryFilterOperators = QueryFilterOperator.iLike) {
    return this.addFilter(key, value, operator);
  }

  public toggleStringFilter(
    key: string,
    value: string | null,
    operator: StringQueryFilterOperators = QueryFilterOperator.iLike
  ) {
    if (value) {
      this.addStringFilter(key, value, operator);
    } else {
      this.removeFilter(key);
    }
    return this;
  }

  public addFilterMetadata(filterKey: string, data: any) {
    if (Object.prototype.hasOwnProperty.call(this.filters, filterKey)) {
      this.filters[filterKey].metadata = data;
    }
    return this;
  }

  public removeMetadata(key: string): void {
    if (Object.prototype.hasOwnProperty.call(this.filters, key)) {
      this.filters[key].metadata = null;
    }
  }

  public addDatePresetFilter(key: string, value: DatePreset, range?: IDateRange) {
    if (value === DatePreset.Custom) {
      if (range?.from && range?.to) {
        const transformedFrom = format(range.from, 'yyyy-MM-dd');
        const transformedTo = format(range.to, 'yyyy-MM-dd');
        const commaSeparatedDateRange = transformedFrom + ',' + transformedTo;
        this.addDateFilter(key, commaSeparatedDateRange, QueryFilterOperator.Range);
      }
    } else if (value === DatePreset.None) {
      this.removeFilter(key);
    } else {
      this.addDateFilter(key, value, QueryFilterOperator.Preset);
    }
    return this;
  }

  public addDateRangeFilter(key: string, range: IDateRange) {
    const transformedFrom = format(range.from, 'yyyy-MM-dd');
    const transformedTo = format(range.to, 'yyyy-MM-dd');
    const commaSeparatedDateRange = transformedFrom + ',' + transformedTo;
    return this.addFilter(key, commaSeparatedDateRange, QueryFilterOperator.Range);
  }

  public addDateFilter(
    key: string,
    value: DatePreset | string,
    operator: DateQueryFilterOperators = QueryFilterOperator.Equal
  ) {
    return this.addFilter(key, value, operator);
  }

  public toggleDateFilter(
    key: string,
    value: DatePreset | string | null,
    operator: DateQueryFilterOperators = QueryFilterOperator.Equal
  ) {
    if (value) {
      this.addDateFilter(key, value, operator);
    } else {
      this.removeFilter(key);
    }
    return this;
  }

  public addNumberFilter(key: string, value: number, operator: NumberQueryFilterOperators = QueryFilterOperator.Equal) {
    return this.addFilter(key, value, operator);
  }

  public addBooleanFilter(
    key: string,
    value: boolean,
    operator: QueryFilterOperator.Equal = QueryFilterOperator.Equal
  ) {
    const convertedValue = value === true ? 1 : 0;
    this.addFilter(key, convertedValue, operator);
    return this;
  }

  public toggleBooleanFilter(
    key: string,
    value: boolean | null,
    operator: QueryFilterOperator.Equal = QueryFilterOperator.Equal
  ) {
    if (value === true || value === false) {
      this.addBooleanFilter(key, value, operator);
    } else {
      this.removeFilter(key);
    }
    return this;
  }

  public toggleNumberFilter(
    key: string,
    value: number | null,
    operator: NumberQueryFilterOperators = QueryFilterOperator.Equal
  ) {
    if (value) {
      this.addNumberFilter(key, value, operator);
    } else {
      this.removeFilter(key);
    }
    return this;
  }

  private addFilter(key: string, value: string | number, operator: QueryFilterOperator) {
    if (value !== null && value !== undefined) {
      this.filters[key] = { value, operator };
    }
    return this;
  }

  public addQueryFilter(key: string, builtFilter: QueryFilter) {
    this.filters[key] = builtFilter;
    return this;
  }

  public mergeFilters(queryFilters: QueryFilters) {
    this.filters = merge(this.filters, queryFilters.filters);
    return this;
  }

  public getFilter(key: string): QueryFilter {
    return this.filters[key];
  }

  public getFilterValue<T>(key: string): T {
    return this.filters[key]?.value as T;
  }

  public removeFilter(key: string) {
    this.filters[key] = undefined;
    return this;
  }

  public toUrlParamObject(includeNull = false): { [key: string]: string | number } {
    const keys = Object.keys(this.filters);
    if (keys.length === 0) {
      return null;
    }
    const queryParams: any = {};
    for (const key of keys) {
      const concatenatedKey: string = 'filter[' + key + ']';
      const filterObj = this.filters[key];
      if (includeNull || (!includeNull && filterObj?.value)) {
        queryParams[concatenatedKey + QueryFilters.VALUE_TOKEN] = filterObj?.value;
        queryParams[concatenatedKey + QueryFilters.OPERATOR_TOKEN] = filterObj?.operator;
      }
    }
    return queryParams;
  }

  /**
   * Parses the given URL parameters or URL and updates the filter object accordingly.
   *
   * @param urlParamsOrUrl ParamsOrUrl - The URL parameters or URL to parse, object has a key ex: "filter[key1][value]".
   * @return {this} - The updated instance of the object.
   */
  public fromUrlParam(urlParamsOrUrl: ParamsOrUrl): this {
    const filterObject = QueryFilters.getFiltersFromUrlParams(urlParamsOrUrl);
    const filterObjectKeys = Object.keys(filterObject);
    filterObjectKeys.forEach((filterDatabaseKey) => {
      this.updateFilter(filterDatabaseKey, filterObject[filterDatabaseKey]);
    });
    return this;
  }

  public static getFiltersFromUrlParams(urlParamsOrUrl: ParamsOrUrl) {
    let paramData: { [filterKey: string]: string } = urlParamsOrUrl as { [filterKey: string]: string };
    if (typeof urlParamsOrUrl === 'string') {
      paramData = {};
      const url = new URL(urlParamsOrUrl);
      const searchParams = new URLSearchParams(url.search);

      for (const pair of searchParams.entries()) {
        paramData[pair[0]] = pair[1];
      }
    }

    return QueryFilters.getFiltersFromParamObject(paramData);
  }

  private updateFilter(filterDatabaseKey: string, filterData: any): void {
    this.filters[filterDatabaseKey] = this.filters[filterDatabaseKey] || ({} as QueryFilter);
    const validKeys: ('value' | 'operator')[] = ['value', 'operator'];
    validKeys.forEach((key) => {
      if (filterData[key]) {
        this.filters[filterDatabaseKey][key] = filterData[key];
      }
    });
  }

  private static getFiltersFromParamObject(params: { [filterKey: string]: string }): QueryFiltersObject {
    const result: { [key: string]: any } = {};
    const paramKeys = Object.keys(params);

    for (const key of paramKeys) {
      const filterKeyMatches = key.match(QueryFilters.filterKeyRegex);
      if (filterKeyMatches) {
        const filterKey = filterKeyMatches[2];
        const operator = filterKeyMatches[3];
        if (!result[filterKey]) {
          result[filterKey] = {};
        }
        result[filterKey][operator] = params[key];
      }
    }

    return result;
  }
}

export enum DatePreset {
  None = 'none',
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  Last14Days = 'last_14_days',
  Last30Days = 'last_30_days',
  CurrentMonth = 'current_month',
  PreviousMonth = 'previous_month',
  YearToDate = 'ytd',
  CurrentYear = 'current_year',
  PreviousYear = 'previous_year',
  Custom = 'custom',
}

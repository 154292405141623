import { Injectable } from '@angular/core';
import { User } from '@entities/users/user.entity';
import { Worker } from '@entities/workers/worker.entity';
import { WorkerService } from '@services/worker/worker.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { UserService } from '@services/user/user.service';
import { BehaviorSubject, EMPTY, Observable, tap } from 'rxjs';
import { UserFeature } from '@entities/users/user-feature.enum';
import { ApiService } from '@services/api/api.service';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';
import { entity } from '@utils/rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUserService {
  private authenticatedUserSubject: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject<AuthenticatedUser>(null);
  public authenticatedUser$: Observable<AuthenticatedUser> = this.authenticatedUserSubject.asObservable();

  public get authenticatedUser(): AuthenticatedUser {
    return this._authenticatedUser;
  }
  private _authenticatedUser: AuthenticatedUser;
  public constructor(
    private workerService: WorkerService,
    private userService: UserService,
    private authService: AuthenticationService,
    private api: ApiService
  ) {
    this.authService.authNotification$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.setAuthenticatedUser(null);
      }
    });
  }

  public loadAuthenticatedUser(): Observable<AuthenticatedUser> {
    const userTokenObject = this.authService.getDecodedToken();
    if (!userTokenObject) {
      this.setAuthenticatedUser(null);
      return EMPTY;
    }
    return this.retrieveAuthenticatedUser();
  }

  public hasFeature(feature: UserFeature): boolean {
    return this._authenticatedUser.hasFeature(feature);
  }

  private retrieveAuthenticatedUser(): Observable<AuthenticatedUser> {
    return this.api.get('auth/auth-user').pipe(
      entity<AuthenticatedUser>(AuthenticatedUser),
      tap((user) => {
        this.setAuthenticatedUser(user);
      })
    );
  }

  /**
   * Retrieves the authenticated user entity which represents the fully hydrated entity based on role (Worker or User-- everything else).
   */
  public retrieveAuthenticatedUserFullEntity(): Observable<User | Worker> {
    if (this._authenticatedUser.isWorker) {
      return this.workerService.retrieveById(this._authenticatedUser.id);
    } else {
      return this.userService.retrieveById(this._authenticatedUser.id);
    }
  }

  /**
   * Updates the authenticated user entity which represents the fully hydrated entity based on role (Worker or User-- everything else).
   * @param updatedInfo
   */
  public updateAuthenticatedUserEntity(updatedInfo: Worker | User): Observable<Worker | User> {
    if (updatedInfo instanceof Worker) {
      return this.updateWorker(updatedInfo);
    } else {
      return this.updateUser(updatedInfo);
    }
  }

  private updateUser(updatedInfo: User): Observable<User | Worker> {
    return this.userService.update(updatedInfo).pipe(
      tap(() => {
        this.retrieveAuthenticatedUser();
      })
    );
  }

  private updateWorker(updatedInfo: Worker): Observable<User | Worker> {
    return this.workerService.update(updatedInfo).pipe(
      tap(() => {
        this.retrieveAuthenticatedUser();
      })
    );
  }

  public setAuthenticatedUser(user: AuthenticatedUser): void {
    this._authenticatedUser = user;
    this.authenticatedUserSubject.next(user);
  }
}

import { UserRole } from '@entities/users/user-role.enum';
import { Entity } from '@jobilla/entity';

export class AssignedWorker extends Entity {
  public id: number = null;
  public areaCode: string = null;
  public clientName: string = null;
  public clientId: number = null;
  public companyName: string = null;
  public companyId: number = null;
  public lastName: string = null;
  public firstName: string = null;
  public roleId: UserRole = null;
  public phone: string = null;
  public latestTimesheetStatus: string = null;
  public email: string = null;
  public externalId: string = null;

  public get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
